export const AC = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="175.608"
    height="111.761"
    viewBox="0 0 175.608 111.761"
  >
    <g>
      <g>
        <polygon
          points="55.388 32.105 82.831 79.928 55.137 63.939 27.694 16.116 55.388 32.105"
          fill="#8dc63f"
        />
        <polygon
          points="55.388 32.105 27.694 111.761 0 95.772 27.694 16.116 55.388 32.105"
          fill="#fff200"
        />
      </g>
      <g>
        <polygon
          points="92.086 15.989 92.086 79.656 120 63.54 119.78 0 92.086 15.989"
          fill="#d7df23"
        />
        <polygon
          points="147.694 111.761 175.608 95.645 120 63.54 92.086 79.656 147.694 111.761"
          fill="#fff200"
        />
        <polygon
          points="147.694 48.095 175.388 32.105 119.78 0 92.086 15.989 147.694 48.095"
          fill="#fff200"
        />
      </g>
    </g>
  </svg>
);
