export const WAG = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="94.2802"
    height="100"
    viewBox="0 0 747.74 793.104"
  >
    <g>
      <path
        d="M747.74,514.968V793.1a80.014,80.014,0,0,1-80.011-80.01v-2.52a80.355,80.355,0,0,1-20,2.52H607.721l4.221-8.441a129.415,129.415,0,0,1,55.787-56.828V598.419L562.756,744.378A117.071,117.071,0,0,1,467.7,793.1l164.982-229.43a117.1,117.1,0,0,1,95.052-48.706Z"
        fill="#214794"
      />
      <g>
        <path
          d="M453.558,514.735V792.487h0a61.5,61.5,0,0,1-61.5-61.5h0V514.735Z"
          fill="#214794"
        />
        <path
          d="M199.747,791.412,0,518.719H0c40.943-14.17,86.959,1.462,113.87,38.692L187.6,659.427c28.032,38.785,32.77,90.264,12.149,131.984Z"
          fill="#214794"
        />
        <path
          d="M191.378,594.868l107.051,149.51A117.068,117.068,0,0,0,393.481,793.1L285.209,642.536A114.545,114.545,0,0,0,191.378,594.868Z"
          fill="#214794"
        />
      </g>
      <g>
        <path
          d="M659.27,45.274c-34.28-46.785-48.249-22.167-37.03-5.984,49.582,71.518,49.582,71.518,60.184,100.954l.294-2.3c5.09-50.829-5.479-66.422-20.108-88-1-1.4-2.322-3.283-3.34-4.673Z"
          fill="#fde64c"
        />
        <path
          d="M189.4,47.473c34.279-46.785,48.249-22.167,37.029-5.984-49.582,71.518-49.582,71.518-60.184,100.954l-.294-2.3c-5.09-50.829,5.48-66.422,20.109-88,1-1.4,2.321-3.283,3.34-4.673Z"
          fill="#fde64c"
        />
        <path
          d="M581.448,250.456a77.274,77.274,0,0,1-14.5,31.869c-20.515,26.242-46.79,47.161-67.591,73.14l-1.826,2.308c-4.3-25.968-13.384-49.151-30.381-77.516a77.273,77.273,0,0,1-10.58-49.48c2.363-20.895,12.623-33.407,19.412-41.687,29.023-35.4,41.712-57.818,48.53-69.863l1.777-3.23C587,222.6,587,222.6,581.448,250.456Z"
          fill="#f48437"
        />
        <path
          d="M290.932,123.221v.066c-.483,6.641-1.824,24.144-14.205,45.407-18.229,31.246-28.3,56.335-34.785,86.569l-.418,2.045-.2,1.011C183.582,173.531,183.582,173.531,185.8,148v-.023c.451-17.015,12.206-44.061,30.774-64.524,19.305-21.275,49.528-21.9,64.9,1.165l.436.648C291.856,99.936,291.2,116.849,290.932,123.221Z"
          fill="#fde64c"
        />
        <path
          d="M556.991,123.221v.066c.483,6.641,1.824,24.144,14.205,45.407,18.228,31.246,28.3,56.335,34.785,86.569l.418,2.045.2,1.011C664.341,173.531,664.341,173.531,662.12,148v-.023c-.451-17.015-12.207-44.061-30.774-64.524-19.3-21.275-49.528-21.9-64.9,1.165l-.436.648C556.07,99.936,556.727,116.849,556.991,123.221Z"
          fill="#fde64c"
        />
        <path
          d="M354.545,463.489c-86.788-57.94-86.788-57.94-99.875-74.894-13.391-18.976-22.253-31.51-10.269-118.321l.528-3.738.418.55c.066.088.11.154.175.241a.321.321,0,0,1,.067.088c6.993,9.5,23.065,31.2,56.291,63.35,8.708,8.466,18.6,18.03,27.222,40.283-.243.044-.506.088-.747.132.241-.044.5-.088.747-.11l.021.022c4.134,11.94,6.707,22.143,6.685,36.039,0,21.791,5.058,37.645,17.481,54.708l.9,1.187a2.641,2.641,0,0,1-.286-.241c.11.11.241.22.352.329Z"
          fill="#ed3541"
        />
        <path
          d="M591.825,390.134l-.065.066c-15.306,19.24-15.392,19.306-99.169,72.5l-1.738,1.1c.572-.484,1.144-.945,1.694-1.451-.617.528-1.21,1.033-1.826,1.539l-.352.22c14.447-17.217,20.824-34.105,21.33-56.489.307-14.028,3.078-24.121,7.453-35.863,9.082-21.879,19.307-31.356,28.322-39.69,32.543-30.124,48.617-51.607,55.477-60.8l2.265-3.056.022.176q-1.154,4.551-2.441,9.169c-.681,2.441-1.385,4.882-2.111,7.345.132-.374.243-.748.352-1.144.483-1.627.946-3.232,1.408-4.838.967-3.386,1.912-6.728,2.858-10.07C616.65,356.228,604.6,372.7,591.825,390.134Z"
          fill="#ed3541"
        />
        <path
          d="M390.67,236.8a78.6,78.6,0,0,1-11.259,43.644c-16.3,27.189-24.936,48.763-29.79,74.466l-.431,2.543C328.66,329.526,301.818,307,281.841,278.6c-4.859-6.907-11.089-18.692-13.175-35.611-2.689-21.813-2.689-21.813,51.146-123.819l1.911-3.616c7.035,12.975,20.13,37.126,49.2,73.082,7.487,9.262,18.8,23.258,19.752,48.163Z"
          fill="#f48437"
        />
        <path
          d="M152.924,104.9c3.923,15.319-2.133,31.333-3.826,46.968-.2,1.758-.462,4.089-.659,5.848v.022c-3.277,27.046-9.807,53.652-11.259,80.985a228.138,228.138,0,0,0,2.749,47.341A278.716,278.716,0,0,1,132.54,172.82a.08.08,0,0,1,.022-.044C136.521,144.323,150.637,95.966,152.924,104.9Z"
          fill="#f48437"
        />
        <path
          d="M695.01,104.9c-3.923,15.319,2.133,31.333,3.826,46.968.2,1.758.462,4.089.66,5.848v.022c3.277,27.046,9.807,53.652,11.258,80.985a228.275,228.275,0,0,1-2.748,47.341A278.752,278.752,0,0,0,715.4,172.82a.082.082,0,0,0-.023-.044C711.413,144.323,697.3,95.966,695.01,104.9Z"
          fill="#f48437"
        />
        <path
          d="M219.351,264.776a134.772,134.772,0,0,1,3.905,44.438c-2.315,28.223,1.136,51.147,11.539,76.651l1.089,2.484c-79.492-66.614-112.394-94.186-71.687-227.7l1.272-3.979c9.927,40.041,43.672,68.211,53.882,108.111Z"
          fill="#f48437"
        />
        <path
          d="M628.57,264.776a134.8,134.8,0,0,0-3.9,44.438c2.315,28.223-1.136,51.147-11.54,76.651l-1.088,2.484c79.492-66.614,112.394-94.186,71.687-227.7l-1.272-3.979c-9.927,40.041-43.672,68.211-53.882,108.111Z"
          fill="#f48437"
        />
        <path
          d="M471.635,366.628c-.813-.066-1.627-.11-2.441-.176.814.044,1.628.088,2.441.154,1.716-9.455,2.7-21.021-3.057-35.071-.835-2-1.341-3.211-17.327-31.488-12.687-22.363-19.152-33.951-28.035-50.97l-.307-.616-1.826,3.518c-7.3,13.963-11.984,22.561-23.857,43.8-18.009,32.235-18.691,33.928-19.482,35.886-5.629,13.83-4.771,25.352-3.21,34.785l2.264-.132c-.77.044-1.517.11-2.264.154v.022c3.825,17.2,8.838,25.7,18.645,38.327,13.326,17.173,22.913,34.632,28.476,51.849l.109-.374c.023-1.033.045-2.089.067-3.144-.022,1.033-.044,2.067-.044,3.078l.461-1.275c6.289-16.843,16.206-33.621,29.465-49.826C462.136,392.421,467.479,383.849,471.635,366.628ZM422.16,426.943v-.681a2.65,2.65,0,0,1,.023-.418C422.16,426.218,422.16,426.569,422.16,426.943Z"
          fill="#ed3541"
        />
        <path
          d="M412.892,488.427c-2.183-8.793-1.02-17.89-2.434-26.754-.215-1.074-.478-2.511-.719-3.58-2.533-11.245-6.734-18.2-8.423-20.668-14.826-21.669-32.363-41.232-48.213-62.106l-.357,3.049c-7.11,68.372-3.213,77.924,53.323,115.134a4.52,4.52,0,0,0,6.87-4.887C412.924,488.549,412.908,488.49,412.892,488.427Z"
          fill="#214794"
        />
        <path
          d="M492.063,378.368l-.357-3.049c-15.85,20.874-33.387,40.437-48.213,62.106-1.689,2.468-5.89,9.423-8.422,20.668-.241,1.069-.5,2.506-.72,3.58-1.414,8.864-.25,17.961-2.434,26.754-.015.063-.031.126-.048.188a4.521,4.521,0,0,0,6.872,4.887C495.276,456.292,499.17,446.74,492.063,378.368Z"
          fill="#214794"
        />
        <path
          d="M266.9,447.3a2.637,2.637,0,0,1-3.179,4.179,301.881,301.881,0,0,1-51.384-40.753h-.022a291.187,291.187,0,0,1-51.736-68.988,2.626,2.626,0,0,1,4.267-3.008c18.636,21.062,41.113,38.522,59.828,59.55,14.116,16.118,26.1,34.06,41.91,48.727Z"
          fill="#214794"
        />
        <path
          d="M344.87,466.9C312,443.6,275.706,425.535,243.77,400.8l1.557,2.943c29.546,66.982,85.984,88.421,167.65,98.156h0a.518.518,0,0,0,.067-1.026c-27.593-5.653-40.05-14.057-63.874-30.91l-4.3-3.058Z"
          fill="#214794"
        />
        <path
          d="M580.122,447.3a2.637,2.637,0,0,0,3.179,4.179,301.842,301.842,0,0,0,51.384-40.753h.023a291.214,291.214,0,0,0,51.736-68.988,2.627,2.627,0,0,0-4.268-3.008c-18.635,21.062-41.113,38.522-59.826,59.55-14.117,16.118-26.1,34.06-41.911,48.727Z"
          fill="#214794"
        />
        <path
          d="M499.954,466.9c32.867-23.308,69.163-41.368,101.1-66.105l-1.557,2.943c-29.546,66.982-85.984,88.421-167.65,98.156h0a.518.518,0,0,1-.067-1.026c27.592-5.653,40.05-14.057,63.874-30.91l4.3-3.058Z"
          fill="#214794"
        />
        <path
          d="M351.861,70.543v.139c-.23,25.336,11.051,39.184,19.257,49.308,30.584,37.588,46.164,66.091,52.036,76.817l1.249,2.335.022.023.324.6c7.1-13.338,21.891-41.124,52.661-80.261,7.928-10.079,18.747-23.857,18.654-49.123v-.069c-.67-12.807-4.022-24-10.033-33.243-3.468-5.34-4.415-6.426-10.471-13.361-26.4-30.144-72.009-32.987-101.922-.162-6.473,7.1-7.466,8.183-11.05,13.454C353.017,51.172,352.163,65.759,351.861,70.543Z"
          fill="#fde64c"
        />
      </g>
    </g>
  </svg>
);
