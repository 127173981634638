export const BSP = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="78.716"
    height="87.125"
    viewBox="0 0 78.716 87.125"
  >
    <g>
      <path
        d="M63.125,45.545,59.689,45.1,45.658,43.274a6.525,6.525,0,0,0,.033-.684c0-.1,0-.2-.008-.3s-.011-.2-.023-.308a.466.466,0,0,0,0-.072c-.007-.114-.023-.224-.038-.338a.105.105,0,0,1-.008-.034c-.015-.1-.034-.209-.053-.312a1.124,1.124,0,0,0-.035-.156c-.026-.125-.057-.254-.091-.38s-.077-.258-.122-.387-.088-.255-.142-.38a6.9,6.9,0,0,0-.807-1.481l-.4-.574L47.315,34.2a8.91,8.91,0,0,0,7.555,5.106,8.778,8.778,0,0,0,2.892-.315,17.667,17.667,0,0,0,5.253,6.466C63.049,45.488,63.087,45.514,63.125,45.545Z"
        fill="#1f448c"
      />
      <polygon
        points="78.716 37.341 64.248 52.97 63.125 45.545 78.716 37.341"
        fill="#3b63ad"
      />
      <path
        d="M63.125,45.545,59.689,45.1,45.658,43.274a6.525,6.525,0,0,0,.033-.684c0-.1,0-.2-.008-.3s-.011-.2-.023-.308a.466.466,0,0,0,0-.072c-.007-.114-.023-.224-.038-.338a.105.105,0,0,1-.008-.034c-.015-.1-.03-.209-.053-.312a1.124,1.124,0,0,0-.035-.156c-.026-.125-.057-.254-.091-.38s-.077-.258-.122-.387-.088-.255-.142-.38a6.9,6.9,0,0,0-.807-1.481l-.4-.574L47.315,34.2a8.91,8.91,0,0,0,7.555,5.106,8.778,8.778,0,0,0,2.892-.315,17.667,17.667,0,0,0,5.253,6.466C63.049,45.488,63.087,45.514,63.125,45.545Z"
        fill="#101b43"
      />
      <path
        d="M47.318,34.2a8.633,8.633,0,0,0,.761,1.32,8.9,8.9,0,0,0,9.683,3.472c-.05-.1-.1-.21-.146-.315s-.093-.21-.137-.316-.081-.187-.12-.284c-.02-.051-.04-.105-.062-.158-.186-.467-.351-.941-.5-1.426v0c-.024-.08-.047-.161-.07-.242s-.049-.174-.071-.26-.048-.177-.068-.265-.046-.187-.069-.28c-.058-.26-.114-.524-.164-.788q-.047-.252-.084-.507c-.015-.084-.026-.172-.037-.257s-.026-.179-.037-.271c-.013-.113-.026-.227-.037-.34s-.023-.231-.034-.345c-.018-.232-.033-.465-.043-.7,0-.117-.009-.232-.011-.348-.012-.469-.005-.939.022-1.414.007-.119.014-.236.023-.355s.018-.237.031-.352c0-.026,0-.053.009-.079.02-.211.045-.418.072-.623.013-.093.026-.187.04-.278s.028-.183.044-.275q.07-.416.158-.819c.019-.086.037-.169.057-.253.014-.06.029-.123.045-.183.019-.086.041-.171.064-.257.026-.1.053-.2.082-.306s.062-.22.1-.328v0c.033-.11.066-.217.1-.323.017-.053.035-.108.053-.161s.037-.108.055-.163c.046-.129.091-.259.139-.389q.123-.33.259-.654c.029-.074.06-.145.093-.217q.266-.618.581-1.211c.051-.1.1-.2.159-.3s.108-.2.165-.292c.089-.156.183-.31.278-.462a.225.225,0,0,1,.022-.035c.062-.1.129-.205.2-.306.009-.016.02-.031.029-.046.037-.056.075-.111.112-.166.051-.077.1-.154.157-.23.035-.051.072-.1.108-.152a.146.146,0,0,1,.02-.025c.081-.118.165-.231.251-.347,0,0,.007,0,.011-.011.1-.137.21-.275.318-.411L58.433,22.1,50.7,30.522l-1.687,1.836Z"
        fill="#ec1a8d"
      />
      <path
        d="M78.716,0l-17.2,18.74A17.089,17.089,0,0,0,60.232,20.1c-.1.121-.209.247-.308.376L58.433,22.1l-7.74,8.428-1.685,1.834L47.315,34.2l-3.359,3.664,1-7.762c.532-.141,1.052-.3,1.565-.485a21.078,21.078,0,0,0,7.88-5c.391-.392.763-.8,1.12-1.217l1.549-1.682L71.487,6.037A35.583,35.583,0,0,1,78.716,0Z"
        fill="#101b43"
      />
      <polygon
        points="63.125 45.545 43.755 56.036 0 37.341 63.125 45.545"
        fill="#3b63ad"
      />
      <polygon
        points="67.697 75.835 52.665 63.402 63.125 45.545 67.697 75.835"
        fill="#c1d4ed"
      />
      <polygon
        points="63.125 45.545 38.772 87.125 43.755 56.036 63.125 45.545"
        fill="#101b43"
      />
    </g>
  </svg>
);
